import React, { Suspense, lazy, useEffect } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { goToLogin } from './components/Services/Interceptors/Token';

/* guarded component for securing routes */
import { guarded, hasAnyAuthorities } from './components/Services/Guards/auth-guard';

/* loader component for Suspense */
import PageLoader from "./components/Common/PageLoader";

/* page layouts */
import BaseBlankPage from "./components/Layout/BaseBlankPage";
import BasePage from "./components/Layout/BasePage";
import { getSystemConfig, getUserDetails } from "./components/Services/Interceptors/Token";

import ReactGA from "react-ga4";

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

/* page contents */
// login / activation views
const Login = lazy(() => import("./components/PortalPages/Login/Login"));
const Saml = lazy(() => import('./components/PortalPages/Login/Saml'));
const NoAccess = lazy(() => import('./components/PortalPages/Login/NoAccess'));
const SystemRedirect = lazy(() => import('./components/PortalPages/Login/SystemRedirect'));
// settings
const Settings = lazy(() => import("./components/PortalPages/Settings/Settings"));
const System = lazy(() => import("./components/PortalPages/Settings/System"));
// hrd views
const Home = lazy(() => import("./components/HRDPages/Home"));
const Inbox = lazy(() => import("./components/HRDPages/Inbox"));
const Delegations = lazy(() => import("./components/HRDPages/Delegations"));
const Delegation = lazy(() => import("./components/HRDPages/Delegation"));
const FormStatus = lazy(() => import("./components/HRDPages/FormStatus"));
const Profile = lazy(() => import("./components/HRDPages/Profile"));
const Confirmation = lazy(() => import("./components/HRDPages/Confirmation"));
const JobCatalog = lazy(() => import("./components/HRDPages/JobCatalog"));
const Reports = lazy(() => import("./components/HRDPages/Reports"));
const Report = lazy(() => import("./components/HRDPages/Report"));
// uma views
const Permissions = lazy(() => import("./components/PortalPages/UMA/Permission/Permissions"));
const Permission = lazy(() => import("./components/PortalPages/UMA/Permission/Permission"));
const Roles = lazy(() => import("./components/PortalPages/UMA/Role/Roles"));
const Role = lazy(() => import("./components/PortalPages/UMA/Role/Role"));
const Users = lazy(() => import("./components/PortalPages/UMA/User/Users"));
const LiveOrg = lazy(() => import("./components/LiveOrgPages/LiveOrg"));
// mdf views
const Launcher = lazy(() => import("./components/MDF/ViewRenderer/Launcher.js"));
const ViewRenderer = lazy(() => import("./components/MDF/ViewRenderer/ViewRenderer.js"));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const loginPages = ["/", "/login", "/saml", "/noaccess", "/systemredirect"];
const skipPagesForGA = ["/", "/login", "/saml"];

const contains = (pages, fullpath) => {
  const pathname = '/' + fullpath.split('/')[1];
  return pages.indexOf(pathname) > -1;
};

const usePageViews = (location) => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: `${location.pathname} Page`});
  }, [location]);
}

const Routes = ({ location }) => {
  const contextRoot = process.env.REACT_APP_CONTEXT_ROOT;
  // Record Google Analytics
  console.log('routes: ', contains(skipPagesForGA, location.pathname.replace(contextRoot, '')), location.pathname, location.pathname.replace(contextRoot, ''))
  if (!contains(skipPagesForGA, location.pathname.replace(contextRoot, ''))) {
    console.log(`GA via Routes: [location=${location.pathname}`, location);
    const userDetails = getUserDetails() || {};
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: `${location.pathname} Page`,
      roles: userDetails?.toUserGroups?.results?.map(g => g.Usergrp).join(','),
      business_segment: `${userDetails.BusSegTxt} (${userDetails.BusSeg})`,
      employee_group: `${userDetails.EmpGrpTxt} (${userDetails.EmpGrp})`,
      employee_subgroup: `${userDetails.EmpSubGrpTxt} (${userDetails.EmpSubGrp})`,
      personal_area: `${userDetails.PATxt} (${userDetails.PA})`,
      personal_subarea: `${userDetails.PSATxt} (${userDetails.PSA})`,
      work_location: `${userDetails.WrkLocTxt} (${userDetails.WrkLoc})`
    });
  }

  if (location.pathname != null && location.pathname.indexOf('/health') > -1)
    return ('true');
  else if (contains(loginPages, location.pathname.replace(contextRoot, ''))) {
    return (
      // Blank Page Layout component wrapper
      <BaseBlankPage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path={contextRoot + '/login'} component={waitFor(Login)} />
            <Route path={contextRoot + '/saml'} component={waitFor(Saml)} />
            <Route path={contextRoot + '/noaccess'} component={waitFor(NoAccess)} />
            <Route path={contextRoot + '/systemredirect'} component={waitFor(SystemRedirect)} />
            <Route path={contextRoot + '/'} component={() => { goToLogin(); return null; }} />
          </Switch>
        </Suspense>
      </BaseBlankPage>
    );
  }
  else if (guarded()) {
    const system = getSystemConfig();
    if(!system?.enableRedirect || system?.enableRedirect && hasAnyAuthorities(['PORTAL_ALL', 'SYSTEM_ALL', 'SYSTEM_PAGE']))
      return (
        // Base Page Layout component wrapper
        <BasePage>
          <Suspense fallback={<PageLoader />}>
            <Switch location={location}>
              <Route path={contextRoot + '/hrd/home'} component={waitFor(Home)} />
              <Route path={contextRoot + '/hrd/inbox'} component={waitFor(Inbox)} />
              <Route path={contextRoot + '/hrd/delegations'} component={waitFor(Delegations)} />
              <Route path={contextRoot + '/hrd/delegation'} component={waitFor(Delegation)} />
              <Route path={contextRoot + '/hrd/form/status'} component={waitFor(FormStatus)} />
              <Route path={contextRoot + '/hrd/job/catalog'} component={waitFor(JobCatalog)} />
              <Route path={contextRoot + '/hrd/reports'} component={waitFor(Reports)} />
              <Route path={contextRoot + '/hrd/report'} component={waitFor(Report)} />
              <Route path={contextRoot + '/hrd/profile'} component={waitFor(Profile)} />
              <Route path={contextRoot + '/hrd/confirmation'} component={waitFor(Confirmation)} />
              <Route path={contextRoot + '/liveorg/home'} component={waitFor(LiveOrg)} />
              <Route path={contextRoot + '/mdf/launcher'} component={waitFor(Launcher)} />
              <Route path={contextRoot + '/mdf/viewrenderer'} component={waitFor(ViewRenderer)} />
              <Route path={contextRoot + '/portal/permissions'} component={waitFor(Permissions)} />
              <Route path={contextRoot + '/portal/permission/:id?'} component={waitFor(Permission)} />
              <Route path={contextRoot + '/portal/roles'} component={waitFor(Roles)} />
              <Route path={contextRoot + '/portal/role/:id?'} component={waitFor(Role)} />
              <Route path={contextRoot + '/portal/users'} component={waitFor(Users)} />
              <Route path={contextRoot + '/portal/settings'} component={waitFor(Settings)} />
              <Route path={contextRoot + '/portal/system'} component={waitFor(System)} />
            </Switch>
          </Suspense>
        </BasePage>
      );
    else
      return (
        <Redirect to={contextRoot+'/systemredirect'} />
      );
  }
  return (
    <Suspense fallback={<PageLoader />}>
      <Route path="/login">
        <Route path={contextRoot + '/login'} component={waitFor(Login)} />
      </Route>
      {/* <Redirect to={contextRoot+'/login'} /> */}
      <Route component={() => { goToLogin(); return null; }} />
    </Suspense>
  );
};

export default withRouter(Routes);
